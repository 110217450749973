<template>
  <vx-card :title="title">
    <!-- territory -->
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="selected_territory"
          :options="fetch_territories"
          @select="onSelectTerritory"
          @search-change="fetchTerritory"
          placeholder=" Type to search"
          track-by="id"
          label="name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <!-- customer -->
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Customer</label>
        <multiselect
          class="selectExample"
          v-model="selected_customer"
          :options="fetch_customers"
          @select="onSelectCustomer"
          @search-change="fetchCustomer"
          placeholder=" Type to search"
          track-by="id"
          label="name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }} - {{ dt.option.customer_address_code}}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }} - {{ dt.option.customer_address_code}}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="vx-row mt-2 ml-2">
      <vs-button
        class="ml-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Browse</vs-button
      >
      <!-- <vs-button class="ml-2" @click="handleSave()">Save</vs-button> -->
    </div>

    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th>Item Name</vs-th>
            <vs-th>Unit Name</vs-th>
            <vs-th>Qty</vs-th>
            <vs-th>Expired date</vs-th>
            <vs-th>Batch</vs-th>
            <vs-th>Serial</vs-th>
            <!-- <vs-th>Action</vs-th> -->
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ data[indextr].item_name }}</vs-td>
              <vs-td>{{ data[indextr].unit_name }}</vs-td>
              <vs-td>
                <vs-input type="number" v-model="data[indextr].qty" :min="1" />
              </vs-td>
              <vs-td>
                <vs-input type="date" v-model="data[indextr].expired_date" />
              </vs-td>
              <vs-td>
                <vs-input type="text" v-model="data[indextr].batch" />
              </vs-td>
              <vs-td>
                <vs-input type="text" v-model="data[indextr].serial" />
              </vs-td>
              <!-- <vs-td>
                <feather-icon
                  title="delete"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="handleDeleteRecord(data[indextr].id)"
                />
              </vs-td> -->
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <!-- form component -->
            <form-component
              @close="handleClose"
              :id="id"
              :url="baseUrl"
              title="create and replace"
              :filter_data="filter_data"
              :table_parent="table"
            ></form-component>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import FormComponent from "./Form";
import moment from "moment";
export default {
  components: {
    FormComponent,
  },
  data() {
    return {
      title: "Stock Outlet",
      baseUrl: "/api/sfa/v1/news",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      deleteId: null,
      id: null,
      table: this.tableDefaultState(),

      // select territory
      fetch_territories: [],
      selected_territory: {},

      // select customer
      fetch_customers: [],
      selected_customer: {},

      // filter data
      filter_data: {
        territory_id: null,
        sales_personal_id: null,
        company_id: null,
      },
    };
  },
  methods: {
    async handleSave() {
      if (this.table.data.length == 0) {
        this.$vs.notify({
          title: "Warning",
          text: "Please add item first",
          color: "warning",
          position: "top-center",
        });
        this.$vs.loading.close();
        return;
      }
      this.$vs.loading();

      let datas = await this.table.data.map((item) => {
        return {
          item_unit_id: item.id,
          quantity: parseInt(item.qty),
          expired_date: item.expired_date,
          batch: item.batch,
          serial: item.serial,
        };
      });

      let params = {
        stock_customers: [
          {
            company_id: this.filter_data.company_id,
            customer_id: this.filter_data.customer_id,
            details: datas,
          },
        ],
      };

      let results = await this.$http.post(
        "api/sfa/v1/adjustment-stock-item",
        params
      );

      if (results.code == 200) {
        this.$vs.notify({
          title: "Success",
          text: "Data has been saved",
          color: "success",
          position: "top-center",
        });
      } else {
        this.$vs.notify({
          title: "Failed",
          text: results.message,
          color: "danger",
          position: "top-center",
        });
      }
      this.$vs.loading.close();

      await this.reloadTableCustomerStock();
    },
    async reloadTableCustomerStock() {
      if (this.selected_customer.company_id == null) {
        this.$vs.notify({
          title: "Failed",
          text: "Please select customer first",
          color: "danger",
        });
        return;
      }

      let params = {
        company_id: this.selected_customer.company_id,
        customer_id: this.selected_customer.customer_id,
      };

      let results = await this.$http.get(
        `/api/sfa/v1/adjustment-stock-item/my`,
        {
          params: params,
        }
      );

      if (results.code == 200) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Success",
          text: "Data is ready",
          color: "success",
        });
        if (results.data.records != null) {
          this.table.data = await results.data.records.map((item) => {
            return {
              id: item.item_unit_id,
              item_name: item.item_name,
              item_code: item.item_code,
              unit_name: item.unit_name,
              qty: item.qty,
              expired_date: moment(item.expired_date).format("YYYY-MM-DD"),
              batch: item.batch,
              serial: item.serial,
            };
          });
        } else {
          this.table.data = [];
        }
      } else {
        this.table.data = [];
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Data is not ready",
          color: "danger",
        });
      }
    },
    async handleDeleteRecord(id) {
      this.table.data = this.table.data.filter((item) => {
        return item.id != id;
      });
    },
    tableDefaultState() {
      return {
        data: [],
        length: 50,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      // search from table.data
      if (searching.length > 3) {
        this.table.data = this.table.data.filter((item) => {
          return item.item_name.toLowerCase().includes(searching.toLowerCase());
        });
      } else if (searching.length == 0) {
        this.reloadTableCustomerStock();
      }
      //
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.detail = false;
      this.$vs.loading.close();
    },
    async handleCreate() {
      await this.reloadTableCustomerStock();
      // set choose table data
      let results = await this.fetchItemUnits(this.selected_territory.id);

      // set filter data
      this.filter_data.territory_id = this.selected_territory.id;
      this.filter_data.customer_id = this.selected_customer.customer_id;
      this.filter_data.company_id = this.selected_customer.company_id;

      this.id = null;
      this.detail = true;
    },
    async fetchItemUnits(territory_id) {
      let results = await this.$http.get(
        "/api/sfa/v1/adjustment-stock-item/public",
        {
          params: {
            territory_id: territory_id,
          },
        }
      );
      this.choose_table_datas = results.data.records;
    },
    getData() {},
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      this.id = id;
      this.detail = true;
    },
    async fetchTerritory(query, id) {
      this.$vs.loading();

      let params = {
        length: 10,
        search: query,
      };

      const personal_id = await this.$store.state.user.currentUser.personal_id;
      const company_id = await this.$store.state.user.currentUser.company_id;
      if (personal_id != null) {
        params.personal_id = personal_id;
      }
      if (company_id != null) {
        params.company_id = company_id;
      }

      let res = await this.$http.get(
        "/api/sfa/v1/territory/personal-territories",
        {
          params: params,
        }
      );

      if (res.code == 200) {
        this.fetch_territories = res.data;
      }
      this.$vs.loading.close();
    },
    async fetchCustomer(query) {
      const territory_id = this.selected_territory.id;
      if (territory_id == null) {
        this.fetch_customers = [];
        return;
      }

      this.$vs.loading();

      let params = {
        length: 10,
        territory_id: territory_id,
        search: query,
      };

      const personalID = await this.$store.state.user.currentUser.personal_id;
      const roles = await this.$store.state.user.currentUser.roles[0].name;
      if (personalID != null && roles != "admin") {
        params.sales_personal_id = personalID;
      }

      let res = await this.$http.get("/api/sfa/v1/customer-address/list", {
        params: params,
      });

      if (res.code == 200) {
        if (res.data.records == null) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "No data found",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
        this.fetch_customers = res.data.records;
      }

      this.$vs.loading.close();
    },
    async onSelectTerritory(data) {
      this.selected_territory = data;
      await this.fetchCustomer();
    },
    async onSelectCustomer(data) {
      this.selected_customer = data;
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  async mounted() {
    await this.fetchTerritory();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
